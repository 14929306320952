import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MySwal from '../../../../../utils/sweetAlert'
import {ChangeEvent, useState, FormEvent, useEffect,useCallback } from 'react'
import { useDropzone } from "react-dropzone";
import {useMission} from '../../core/MissionProvider'
import axiosInstance from '../../../../../utils/axios'
import axiosInstanceFormData from '../../../../../utils/axiosFormData'
import {useParams} from 'react-router-dom'
import {IStepperProps} from '../../../../../types/types'
import {useAuth} from '../../../auth'
import { Widget } from '@typeform/embed-react'
import axiosInstanceTypeForm from '../../../../../utils/axiosTypeForm'

const CSRStep: React.FC<IStepperProps> = ({activeStep, setActiveStep, isRepresentativeView}) => {
  const [downloadLink, setDownloadLink] = useState(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [hasCSR, setHasCSR] = useState(false)
  const {missionId} = useParams()
  const {currentMissionRegistration, setLoadingScreen,fetchMissionDetails} = useMission()
  const {currentUser} = useAuth()
  const currentUrl = window.location.href;
  const showBackToDetailsButton = !currentUrl.includes('representative');
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const maxSize = 5 * 1024 * 1024; // 5 MB
      if (file.size > maxSize) {
        MySwal.fire({
          text: 'File size exceeds the limit of 5 MB.',
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        return;
      }
      const allowedFileTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ];
      if (!allowedFileTypes.includes(file.type)) {
        MySwal.fire({
          text: 'Invalid file type. Please select a PDF, DOCX, XLSX, or PPT file.',
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        return;
      }
      setSelectedFile(file);
      console.log('File selected:', file);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false, // Ensure only one file is allowed
  });
  const sampleTemplateLink = 'https://strapi-aws-s3-bmm.s3.ap-southeast-1.amazonaws.com/USABC_2025_CSR_Template_52e6ef2895.pptx'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/missions/${missionId}?populate=missionRegistrationsId.memberId.memberCsr.csrFile`);
        const data = response.data;
        const csrFileArray =
        data?.data?.attributes?.missionRegistrationsId?.data?.[0]?.attributes?.memberId?.data?.attributes?.memberCsr?.data;
        const csrFile =  csrFileArray?.[csrFileArray.length - 1] ? csrFileArray?.[csrFileArray.length - 1].attributes?.csrFile?.data?.attributes : null;
        setDownloadLink(data.data.attributes.csrLink ? data.data.attributes.csrLink  : '#')
        if(csrFile){
          setSelectedFile(csrFile);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [missionId]);
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHasCSR(event.target.checked)
  }
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleFormSubmit = async () => {
    setFormSubmitted(true);
    await axiosInstance.put(`/api/mission-registrations/${currentMissionRegistration?.id}`, {
      data: {
        submittedCSRStatus: 2,
        billingStatus: 2,
      },
    })
    await axiosInstance.post(`/api/registration-logs`, {
      data:{
        title: 'Uploaded',
        message: 'Uploaded the CSR',
        company: currentMissionRegistration?.compDisplayName,
        category: 'CSR',
        user_id: currentUser?.id,
        mission: missionId,
      }
    })
    MySwal.fire({
      title: 'CSR Submitted',
      icon: 'success',
      confirmButtonColor: '#1976d2',
      confirmButtonText: 'Next',
    }).then(() => {
      if (isRepresentativeView) {
        window.location.href = `/representative`
      } else {
        window.location.href = `/missions/view/${missionId}`
      }
    })
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5 MB
      if (file.size > maxSize) {
        MySwal.fire({
          text: 'File size exceeds the limit of 5 MB.',
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
      const allowedFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation"];
      if (!allowedFileTypes.includes(file.type)) {
        MySwal.fire({
          text: 'Invalid file type. Please select a PDF, DOCX, XLSX, or PPT file.',
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
      setSelectedFile(file)
    }
  };


  const handleFileUpload = async (response) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('files', selectedFile); // Attach the selected file
      formData.append('refId', `${response.data.data.id}`); // ID from API response
      formData.append('ref', 'api::member-csr.member-csr'); // Reference model
      formData.append('field', 'csrFile'); // Field in the model
      formData.append('path', 'csrFile'); // Path for the uploaded file
  
      try {
        const uploadResponse = await axiosInstanceFormData.post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure correct headers
          },
        });
        console.log('File uploaded successfully:', uploadResponse.data);
      } catch (error) {
        console.error('Error uploading file:', error);
        MySwal.fire({
          title: 'File Upload Failed',
          text: 'An error occurred while uploading the file. Please try again.',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonColor: '#1976d2',
        });
      }
    }
  };
  const handleRemoveFile = () => {
    setSelectedFile(null);
  };
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      setLoadingScreen(true)
      //@ts-ignore
      if (hasCSR && downloadLink?.includes('@')){
        await axiosInstance
        .post(`/api/member-csrs`, {
          data: {
            //@ts-ignore
            member: currentMissionRegistration?.memberId?.data.id,
          },
        })
        .then(async (response) => {
          await handleFileUpload(response);
        });
      }
      else if (hasCSR) {
        axiosInstance.put(`/api/mission-registrations/${currentMissionRegistration?.id}`, {
          data: {
            submittedCSRStatus: 2,
            billingStatus: 2,
          },
        })
        await axiosInstance.post(`/api/registration-logs`, {
          data:{
            title: 'Declined',
            message: 'Declined the CSR',
            company: currentMissionRegistration?.compDisplayName,
            category: 'CSR',
            user_id: currentUser?.id,
            mission: missionId,
          }
        })
        MySwal.fire({
          title: 'CSR Declined',
          icon: 'success',
          confirmButtonColor: '#1976d2',
          confirmButtonText: 'Next',
        }).then(() => {
          if (isRepresentativeView) {
            window.location.href = `/representative`
          } else {
            window.location.href = `/missions/view/${missionId}`
          }
        })
      } else {
        axiosInstance.put(`/api/mission-registrations/${currentMissionRegistration?.id}`, {
          data: {
            submittedCSRStatus: 2,
            billingStatus: 2,
          },
        })
        await axiosInstance
          .post(`/api/member-csrs`, {
            data: {
              //@ts-ignore
              member: currentMissionRegistration?.memberId?.data.id,
            },
          })
          .then(async (response) => {
            const formData = new FormData()
            if (selectedFile) {
              formData.append('files', selectedFile)
              formData.append('refId', `${response.data.data.id}`)
              formData.append('ref', 'api::member-csr.member-csr')
              formData.append('field', 'csrFile')
              formData.append('path', 'csrFile')
            }
            await axiosInstanceFormData.post('/api/upload', formData)
          })
          await axiosInstance.post(`/api/registration-logs`, {
            data:{
              title: 'Uploaded',
              message: 'Uploaded the CSR',
              company: currentMissionRegistration?.compDisplayName,
              category: 'CSR',
              user_id: currentUser?.id,
              mission: missionId,
            }
          })
        MySwal.fire({
          title: 'CSR Uploaded',
          icon: 'success',
          confirmButtonColor: '#1976d2',
          confirmButtonText: 'Next',
        }).then(() => {
          if (isRepresentativeView) {
            window.location.href = `/representative`
          } else {
            window.location.href = `/missions/view/${missionId}`
          }
        })
      }
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Please attach a file or decline to provide CSR materials.',
        timer: 2000,
        icon: 'error',
        showConfirmButton: false,
        allowOutsideClick: false,
      })
    } finally {
      setLoadingScreen(false)
    }
  }
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
       {/* @ts-ignore */}
       {downloadLink && downloadLink !== '#' && !(downloadLink.includes('.') || downloadLink.includes('https')) ? (
  <>
    <Widget
      id={downloadLink}
      style={{ width: '100%', height: '600px' }}
      className="my-form"
      onSubmit={handleFormSubmit}
    />
    {formSubmitted && <p>Redirecting...</p>}
    <Container>
      <Grid container spacing={2} mt={0.2}>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="edit-csr-decline"
                  name="csr_decline"
                  checked={hasCSR}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label={
                //@ts-ignore
                <strong>
                  {currentMissionRegistration?.compDisplayName} will not provide CSR materials for
                  this registration.
                </strong>
              }
            />
          </FormGroup>
        </Grid>
        <Grid item textAlign="right" xs={12}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            sx={{
              float: 'left',
              marginRight: 2,
            }}
            onClick={() => setActiveStep(activeStep - 1)}
          >
            Back
          </Button>
          {showBackToDetailsButton && (
            <Button
              type="button"
              variant="contained"
              color="primary"
              href={`/missions/view/${missionId}`}
              sx={{
                float: 'left',
              }}
            >
              Back to Details Page
            </Button>
          )}
          <Button type="button" variant="contained" color="primary" onClick={handleSubmit}>
            Next
          </Button>
        </Grid>
      </Grid>
    </Container>
     {/* @ts-ignore */}
  </>) : downloadLink && downloadLink.includes('@') ? (
      <>
      <Typography variant="h5" gutterBottom>
        A submission file has been created and will be sent to{' '}
        <a href={`mailto:${downloadLink}`} style={{ textDecoration: 'none', color: '#1976d2' }}>
          {downloadLink}
        </a>
        . You may choose to decline to provide CSR materials.
      </Typography>
      <Typography variant="body1" mb={2}>
        Download the sample template below to prepare your submission file:
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        href={sampleTemplateLink}
        target="_blank"
        rel="noopener noreferrer"
        startIcon={<CloudUploadIcon />}
        sx={{ marginBottom: 2 }}
      >
        Download Sample Template
      </Button>

      <Container>
      {!selectedFile && (
        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed #1976d2",
            borderRadius: "8px",
            padding: "16px",
            textAlign: "center",
            backgroundColor: isDragActive ? "#e3f2fd" : "#f5f5f5",
            transition: "background-color 0.3s",
            cursor: "pointer",
            marginBottom: "16px",
          }}
        >
          <input {...getInputProps()} />
          <Typography variant="body1" color="textSecondary">
            {isDragActive
              ? "Drop your file here..."
              : "Drag and drop your file here, or click to browse"}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
            Supported file types: .pdf, .docx, .xlsx
          </Typography>
        </Box>
      )}

      {/* File Display */}
      {selectedFile && (
        <List
          sx={{
            marginBottom: "16px",
            padding: 0,
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
            }}
          >
            <ListItemIcon>
              <InsertDriveFileIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={selectedFile.name}
              secondary={`Size: ${(selectedFile.size / 1024 / 1024).toFixed(
                2
              )} MB`}
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
            <IconButton onClick={handleRemoveFile}>
              <DeleteIcon color="error" />
            </IconButton>
          </ListItem>
        </List>
      )}
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="edit-csr-decline"
                    name="csr_decline"
                    checked={hasCSR}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label={
                  //@ts-ignore
                  <strong>
                    {currentMissionRegistration?.compDisplayName} will not provide CSR materials for
                    this registration.
                  </strong>
                }
              />
            </FormGroup>
          </Grid>
          <Grid item textAlign="right" xs={12}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              sx={{
                marginRight: 2,
              }}
              onClick={() => setActiveStep((prevStep) => prevStep - 1)}
            >
              Back
            </Button>
            <Button type="button" variant="contained" color="primary" onClick={handleSubmit}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
) : (
  <>
    <Typography variant="h5">
      No Form has been provided at the moment. You may choose to decline to provide CSR materials.
    </Typography>
    <Container>
      <Grid container spacing={2} mt={0.2}>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="edit-csr-decline"
                  name="csr_decline"
                  checked={hasCSR}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label={
                //@ts-ignore
                <strong>
                  {currentMissionRegistration?.compDisplayName} will not provide CSR materials for
                  this registration.
                </strong>
              }
            />
          </FormGroup>
        </Grid>
        <Grid item textAlign="right" xs={12}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            sx={{
              marginRight: 2,
            }}
            onClick={() => setActiveStep(activeStep - 1)}
          >
            Back
          </Button>
          <Button type="button" variant="contained" color="primary" onClick={handleSubmit}>
            Next
          </Button>
        </Grid>
      </Grid>
    </Container>
  </>
    )}
    </Box>
  )
}

export default CSRStep
