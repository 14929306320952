import {CKEditor} from '@ckeditor/ckeditor5-react'
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  createFilterOptions,
} from '@mui/material'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Container, Form, InputGroup, Row} from 'react-bootstrap'
import {SubmitHandler, useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import {TMission} from '../../../../types/types'
import axiosInstance from '../../../../utils/axios'
import MySwal from '../../../../utils/sweetAlert'
import {useAuth} from '../../auth'
import {useMission} from '../core/MissionProvider'
import TimezoneSelect from 'react-timezone-select';

type GeneralMissionsFormProps = {
  title: string
  action: string
}

type UserManager = {
  id?: number
  name: string
  email: string
  user_manager?: number
  mission_relations?: number
}

const filter = createFilterOptions<UserManager>()

const MissionsForm: React.FC<GeneralMissionsFormProps> = ({title, action}) => {
  const navigate = useNavigate()
  const {setLoadingScreen, loadingScreen} = useMission()
  const {currentUser} = useAuth()
  const today = new Date().toISOString().split('T')[0]
  const [feesDescription, setFeesDescription] = useState<string | null | undefined>()
  const [feeDescCount, setFeeDescCount] = useState<number>(0)
  const [userManagers, setUserManagers] = useState<UserManager[]>([])
  const [selectedUserManagers, setSelectedUserManagers] = useState<UserManager[]>([])
  const [newManager, setNewManager] = useState<UserManager>({
    name: '',
    email: '',
  })
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openDialogEdit, setOpenDialogEdit] = useState<boolean>(false)
  const [selectedChipData, setSelectedChipData] = useState(null);
  const defValues = {
    title: '',
    msEndDate: '',
    msStartDate: '',
    regOpenDate: '',
    regCloseDate: '',
    refundCutDate: '',
    feesDescription: '',
    participant: 0,
    createdByUser: currentUser?.id,
    hasCsr: false,
    primaryContact: '',
    priceAfterCOB: 0,
    priceBeforeCOB: 0,
    excessParticipantCost: 0,
    COBDate: '',
    timezone: ''
  }

  const minDate = new Date().toISOString().split('T')[0]
  const [defaultValues, setDefaultvalues] = useState<Partial<TMission> | undefined>(defValues)
  const [selectedTimezone, setSelectedTimezone] =useState<string>('');
  const [timezoneError, setTimezoneError] = useState<string>('')
  const [showCSRLinkInput, setShowCSRLinkInput] = useState(false);
  const [showCSRLinkDirect, setShowCSRLinkDirect] = useState(false);

  const {missionId} = useParams()

  useEffect(() => {
    if (defaultValues?.timezone !== '') {
      setSelectedTimezone(defaultValues?.timezone ? defaultValues?.timezone: '')
    }
  }, [defaultValues])

  const getMissionDetails = async () => {
    const {data} = await axiosInstance.get(`/api/missions/${missionId}`)
    setDefaultvalues({
      ...data.data.attributes,
      id: data.data.id,
    })
  }

  const getUserManagers = async () => {
    const {data} = await axiosInstance.get('/api/mission-managers?filters[email][$containsi]=@usasean.org&pagination[page]=1&pagination[pageSize]=100')
    setUserManagers(
      data.data.map((userManagers) => {
        return {
          ...userManagers.attributes,
          id: userManagers.id,
        }
      })
    )
  }
  useEffect(() => {
    if (userManagers.length === 0) {
      getUserManagers()
    }
  }, [])
  const fetchUserManagers = async () => {
    const {data} = await axiosInstance.get(`/api/mission-managers?populate=mission_relations&filters[mission_relations][id]=${missionId}&pagination[page]=1&pagination[pageSize]=100`)
    setSelectedUserManagers(
      data.data.map((userManagers) => {
        return {
          ...userManagers.attributes,
          id: userManagers.id,
        }
      })
    )
  }

  useEffect(() => {
    if (action === 'edit') {
      fetchUserManagers()
    }
  }, [])

  useEffect(() => {
    if (action === 'edit') {
      try {
        setLoadingScreen(true)
        getMissionDetails()
      } catch (error) {
        console.error('Something went wrong. Please check fetching of mission details.')
      } finally {
        setTimeout(() => setLoadingScreen(false), 300)
      }
    } else {
      setDefaultvalues(defValues)
      setFeesDescription(null)
    }
  }, [action, currentUser?.id, missionId])

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
    setValue,
    getValues,
  } = useForm<TMission>({
    defaultValues,
  })

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues)
    }
    if(defaultValues?.hasCsr){
      setShowCSRLinkInput(true)
    }
  }, [defaultValues, reset])
  console.log(defaultValues)

  const convertDateToISOString = (date: string): string => {
    return new Date(date).toISOString().split('T')[0]
  }

  const submitHandler: SubmitHandler<TMission> = async (data) => {
    if (!selectedTimezone) {
      setTimezoneError('Timezone is required');
      return;
    }
    else{
      setTimezoneError('');
    }
    const payload = {
      ...data,
      feesDescription,
      msStartDate: data.msStartDate ? convertDateToISOString(data.msStartDate) : '',
      msEndDate: data.msEndDate ? convertDateToISOString(data.msEndDate) : '',
      regOpenDate: data.regOpenDate ? convertDateToISOString(data.regOpenDate) : '',
      regCloseDate: data.regCloseDate ? convertDateToISOString(data.regCloseDate) : '',
      refundCutDate: data.refundCutDate ? convertDateToISOString(data.refundCutDate) : '',
      COBDate: data.COBDate ? convertDateToISOString(data.COBDate) : '',
      isActive: action !== 'edit' ? true : true,
      isDraft: action !== 'edit' ? false : null,
      //@ts-ignore
      timezone: selectedTimezone.value,
      //@ts-ignore
      timezone_abbrev: selectedTimezone.abbrev
    }
    setLoadingScreen(true)
    if (action !== 'edit') {
      const primaryEmail = await axiosInstance.get(`/api/mission-managers?filters[email]=${payload.primaryContact}`)
      const result = await axiosInstance.post('/api/missions', {data: payload}).then((res)=>{
        if(primaryEmail.data.data.length !==0){
           axiosInstance.put(`/api/missions/${res.data.data.id}`, 
              {data:{
                mission_managers: primaryEmail.data.data[0].id
              }
            })
        }
        else{
          axiosInstance.post(`/api/mission-managers`,{
            data:{
              name: payload.primaryContact,
              email: payload.primaryContact,
              mission_relations: missionId
            }
          })
        }
      })
      
    } else {
      await axiosInstance.put(`/api/missions/${missionId}`, {data: payload}).then(({data}) =>
        setDefaultvalues({
          ...data.attributes,
          id: data.id,
        })
      )
      const managersToPost = selectedUserManagers.filter(selectedManager => {
        return !userManagers.some(userManager => userManager.id === selectedManager.id);
      });
      const currentManagerIds = selectedUserManagers
      .filter(manager => manager && manager.id != null) 
      .map(manager => manager.id);
      if (currentManagerIds.length === 0) {
        const postData = axiosInstance.put(`/api/missions/${missionId}`, {
          data: {
            mission_managers: {
              set: [],
            },
          },
        });
        try {
          const result = await postData;
          console.log(result);
        } catch (error) {
          console.error(error);
        }
      }else {
      const postData = selectedUserManagers.map(manager => {
        console.log(manager,'manager')
         if (manager.id) {
            return axiosInstance.put(`/api/missions/${missionId}`, {
              data: {
                mission_managers: {
                  set: currentManagerIds,
                },
              },
            });
        } else {
          return axiosInstance.post('/api/mission-managers/bulk', {
            data: managersToPost,
          }).then(response => {
            const newManagerIds = response.data.data.ids;
            console.log('newManagerIds', newManagerIds)
            const updateRequests = newManagerIds.map(newManagerId => {
              return axiosInstance.put(`/api/mission-managers/${newManagerId}`, {
                data: {
                  mission_relations: missionId,
                },
              });
            });
    
              return Promise.all(updateRequests);
            });
          }
      });
      const results = await Promise.all(postData);
      console.log(results);
      }
    }

    setLoadingScreen(false)
    MySwal.fire({
      text: action !== 'edit' ? 'Mission Created' : 'Mission Updated',
      timer: 2000,
      icon: 'success',
      showConfirmButton: false,
    }).then(() => {
      navigate(-1)
    })
  }

  const handleInputChange = useCallback((data) => {
    setFeesDescription(data)
  }, [])

  const cancelAndGoBack = () => {
    reset()
    navigate(-1)
  }
  const handleClose = () => {
    setOpenDialog(false)
    setOpenDialogEdit(false)
    setSelectedChipData(null)
    setNewManager({
      name: '',
      email: '',
    })
  }
  const handleCloseEdit = () => {
    setOpenDialogEdit(false)
    setNewManager({
      name: '',
      email: '',
    })
  }
  const handleChipClick = (selectedOption) => {
    setSelectedChipData(selectedOption)
    setOpenDialogEdit(true)
  }

  const handleTimezoneChange = (timezone) => {
    setSelectedTimezone(timezone);
    setTimezoneError('');
  };
  
  const handleInputEditChange = (field, value) => {
  setSelectedChipData((prev) => ({
    //@ts-ignore
    ...prev,
    [field]: value,
  }));
};

  const handleUpdateManager = async() =>{
    setLoadingScreen(true)
    console.log(selectedChipData)
    //@ts-ignore
    await axiosInstance.put(`/api/mission-managers/${selectedChipData?.id}`,{
      data:{
          //@ts-ignore
        name: selectedChipData?.name,
          //@ts-ignore
        email: selectedChipData?.email
      }
    })
    await fetchUserManagers()
    setLoadingScreen(false)
  }
  const customTimezones = {
    //SEA TIMEZONE
    'Asia/Brunei': 'Brunei Darussalam Standard Time (BNT)',
    'Asia/Phnom_Penh': 'Cambodia - Indochina Time (ICT)',
    'Asia/Jakarta': 'Indonesia - Western Indonesian Time (WIB) (regions like Sumatra, Java)',
    'Asia/Makassar': 'Indonesia - Central Indonesian Time (WITA) (regions like Bali, South Kalimantan)',
    'Asia/Jayapura': 'Indonesia - Eastern Indonesian Time (WIT) (regions like Papua, Maluku)',
    'Asia/Vientiane': 'Laos - Indochina Time (ICT)',
    'Asia/Kuala_Lumpur': 'Malaysia - Malaysia Time (MYT)',
    'Asia/Yangon': 'Myanmar - Myanmar Time (MMT)',
    'Asia/Manila': 'The Philippines - Philippine Time (PHT)',
    'Asia/Singapore': 'Singapore - Singapore Standard Time (SGT)',
    'Asia/Bangkok': 'Thailand - Indochina Time (ICT)',
    'Asia/Ho_Chi_Minh': 'Vietnam - Indochina Time (ICT)',
    // USA timezones
    'America/New_York': 'Eastern Time (ET)',
    'America/Chicago': 'Central Time (CT)',
    'America/Denver': 'Mountain Time (MT)',
    'America/Los_Angeles': 'Pacific Time (PT)',
  };

  const handleHasCsrChange = (e) => {
    const hasCsrValue = e.target.value === 'true' && !csrLinkFetch.includes('@');
    const hasCsrDirect = e.target.value === 'true' && csrLinkFetch.includes('@');
    setShowCSRLinkInput(hasCsrValue);
    setShowCSRLinkDirect(hasCsrDirect)
  };

  const csrLinkFetch = getValues('csrLink') || '';
  return (
    <>
      <Container className='w-800px'>
        <Row className='mt-15'>
          <Col>
            <h1>{title}</h1>
            <p>Basic mission information, fees, and deadlines</p>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit(submitHandler)}>
          <Row className='mt-5'>
            <Col>
              <Form.Group controlId='title'>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type='text'
                  {...register('title', {
                    required: 'Title is required',
                  })}
                />
                {errors.title && (
                  <Form.Text className='text-danger'>{errors.title.message}</Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-5'>
            <h3> Mission Dates </h3>
            <Col>
              <Form.Group controlId='msStartDate'>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type='date'
                  placeholder='MM/DD/YYYY'
                  {...register('msStartDate', {required: 'Start Date is required'})}
                />
                {errors.msStartDate && (
                  <Form.Text className='text-danger'>{errors.msStartDate.message}</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='msEndDate'>
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type='date'
                  placeholder='MM/DD/YYYY'
                  {...register('msEndDate', {required: 'End Date is required'})}
                />
                {errors.msEndDate && (
                  <Form.Text className='text-danger'>{errors.msEndDate.message}</Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-5'>
            <h3> Registration Dates </h3>
            <Col>
              <Form.Group controlId='regOpenDate'>
                <Form.Label>Open Date</Form.Label>
                <Form.Control
                  type='date'
                  placeholder='Enter Registration Dates'
                  {...register('regOpenDate', {required: 'Open Date is required'})}
                />
                {errors.regOpenDate && (
                  <Form.Text className='text-danger'>{errors.regOpenDate.message}</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='regCloseDate'>
                <Form.Label>Close Date</Form.Label>
                <Form.Control
                  type='date'
                  placeholder='MM/DD/YYYY'
                  {...register('regCloseDate', {required: 'Close Date is required'})}
                />
                {errors.regCloseDate && (
                  <Form.Text className='text-danger'>{errors.regCloseDate.message}</Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId='feesDescription' className='mt-2'>
            <Form.Label>Fee Description</Form.Label>
            <CKEditor
              editor={Editor}
              id={`editor_${defaultValues?.id}`}
              onReady={(editor) => {
                editor.setData(defaultValues?.feesDescription ?? ' ')
              }}
              onChange={(event, editor) => {
                const wordCountPlugin = editor.plugins.get('WordCount')
                setFeeDescCount(wordCountPlugin.characters)
                const data = editor.getData()
                handleInputChange(data)
              }}
            />
            <div
              id='word-count'
              style={{
                textAlign: 'right',
                color: feeDescCount > 2500 ? 'red' : 'black',
                fontSize: '10px',
                fontStyle: 'italic',
              }}
            >
              Character count: {feeDescCount}/2500
            </div>
          </Form.Group>
          <Row className='mt-5'>
            <Col>
              <Form.Group controlId='refundCutDate'>
                <Form.Label>Fee refund Cut-off Date</Form.Label>
                <Form.Control
                  type='date'
                  placeholder='MM/DD/YYYY'
                  {...register('refundCutDate', {required: 'Fee refund Cut-off Date is required'})}
                />
                {errors.refundCutDate && (
                  <Form.Text className='text-danger'>{errors.refundCutDate.message}</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='participant'>
                <Form.Label>Participant Limit (per registration)</Form.Label>
                <Form.Control
                  type='number'
                  min={1}
                  {...register('participant', {required: 'Participant Limit is required'})}
                />
                {errors.participant && (
                  <Form.Text className='text-danger'>{errors.participant.message}</Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col>
              <Form.Group controlId='hasCsr'>
                <Form.Label>Request CSR Materials</Form.Label>
                <Form.Control as='select' {...register('hasCsr')} onChange={handleHasCsrChange}>
                  <option value='true' defaultValue={'false'}>
                    Yes - Typeform
                  </option>
                  <option value='true' defaultValue={'false'}>
                    Yes - Direct Submission
                  </option>
                  <option value='false'>No</option>
                </Form.Control>
              </Form.Group>
            </Col>
            {showCSRLinkInput && (
              <Col>
                <Form.Group controlId='csrLink'>
                  <Form.Label>CSR Link</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter CSR Link'
                    required={showCSRLinkInput}
                    {...register('csrLink')}
                  />
                </Form.Group>
              </Col>
            )}
             {showCSRLinkDirect && (
              <Col>
                <Form.Group controlId='csrLink'>
                  <Form.Label>CSR Email Submission</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter CSR Email'
                    required={showCSRLinkDirect}
                    {...register('csrLink')}
                  />
                </Form.Group>
              </Col>
            )}
            <Col>
              <Form.Group controlId='csr'>
                <Form.Label>Primary Email</Form.Label>
                <Form.Control
                  type='email'
                  min={1}
                  {...register('primaryContact', {required: 'Primary Email is required'})}
                />
                {errors.participant && (
                  <Form.Text className='text-danger'>{errors.participant.message}</Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col>
              <Form.Group controlId='priceBeforeCOB'>
                <Form.Label>COB Date</Form.Label>
                <InputGroup>
                  <Form.Control
                    type='date'
                    placeholder='MM/DD/YYYY'
                    {...register('COBDate', {required: 'Field is required'})}
                  />
                </InputGroup>
                {errors.priceBeforeCOB && (
                  <Form.Text className='text-danger'>{errors.priceBeforeCOB.message}</Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId='excessParticipantCost'>
                <Form.Label>Additional Participant Fee</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type='number'
                    min={1}
                    {...register('excessParticipantCost', {required: 'Field is required'})}
                  />
                </InputGroup>
                {errors.excessParticipantCost && (
                  <Form.Text className='text-danger'>
                    {errors.excessParticipantCost.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className='mt-5'>
            <Col>
              <Form.Group controlId='priceBeforeCOB'>
                <Form.Label>Price before COB</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type='number'
                    min={1}
                    {...register('priceBeforeCOB', {required: 'Field is required'})}
                  />
                </InputGroup>
                {errors.priceBeforeCOB && (
                  <Form.Text className='text-danger'>{errors.priceBeforeCOB.message}</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='priceAfterCOB'>
                <Form.Label>Price after COB</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type='number'
                    min={1}
                    {...register('priceAfterCOB', {required: 'Field is required'})}
                  />
                </InputGroup>
                {errors.priceAfterCOB && (
                  <Form.Text className='text-danger'>{errors.priceAfterCOB.message}</Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <Form.Group controlId='user-manager'>
                <Form.Label>User Managers</Form.Label>
                <Autocomplete
                  multiple
                  id='fixed-tags-demo'
                  value={selectedUserManagers}
                  onChange={(event, newValue) => {
                    if (Array.isArray(newValue) && newValue.length > 0) {
                      let hasAddRegex = /"([^"]*)"/g
                      //@ts-ignore
                      let matches = newValue[newValue.length - 1 ?? 0].name.match(hasAddRegex);
                      if(matches) {
                        const newEntry = matches[0].replace(/"/g, '')
                        setNewManager((prev) => ({...prev, name: newEntry}))
                        setOpenDialog(true)
                      } else {
                        setSelectedUserManagers(newValue)
                      }
                    } else {
                      //Removes from the userManagers array 
                      setSelectedUserManagers(newValue)
                    }
                  }}
                  sx={{
                    border: '1px solid ##dbdfe9 !important',
                    borderRadius: '0.475rem',
                  }}
                  options={userManagers}
                  getOptionLabel={(uM) => uM.name}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params)

                    if (params.inputValue !== '') {
                      filtered.push({
                        name: `Add "${params.inputValue}"`,
                        email: params.inputValue,
                      })
                    }

                    return filtered
                  }}
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => (  
                      <Chip label={option.name} {...getTagProps({index})} key={`_index_${index}`} onClick={()=>{option.id ? handleChipClick(option): handleClose()}} />
                    ))
                  }}
                  renderInput={(params) => <TextField {...params} size='small' />}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='timezone-selector'>
                <Form.Label>Timezone</Form.Label>
                <TimezoneSelect
                  value={selectedTimezone}
                  onChange={(timezone) => {
                    handleTimezoneChange(timezone)
                  }}
                  timezones={customTimezones}
                />
                {timezoneError && (
                  <Form.Text className='text-danger'>{timezoneError}</Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className='my-5'>
            <Col>
              <Button type='submit' className='mt-2 float-end' disabled={feeDescCount > 2500}>
                {action === 'edit' ? 'Update' : ' Submit'}
              </Button>
              <Button
                className='mt-2 float-end btn-secondary'
                style={{marginRight: '10px'}}
                onClick={cancelAndGoBack}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      <Dialog open={openDialog} onClose={handleClose} fullWidth>
        <DialogTitle>Add User Manager</DialogTitle>
        <DialogContent>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    type='text'
                    value={newManager.name}
                    onChange={(e) =>
                      setNewManager((prev) => {
                        return {
                          ...prev,
                          name: e.target.value,
                        }
                      })
                    }
                    required
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className='mt-2'>
                <Form.Label>Email</Form.Label>
                <InputGroup>
                  <Form.Control
                    type='text'
                    value={newManager.email}
                    onChange={(e) =>
                      setNewManager((prev) => {
                        return {
                          ...prev,
                          email: e.target.value,
                        }
                      })
                    }
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className='mt-2 float-end btn-secondary'>
            Cancel
          </Button>
          <Button
            onClick={() => {
              setSelectedUserManagers((prev) => [...prev, newManager])
              handleClose()
            }}
            variant='contained'
            className='mt-2 float-end btn-primary'
            disabled={newManager.email.length === 0 || newManager.name.length === 0}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogEdit} onClose={handleCloseEdit} fullWidth>
        <DialogTitle>Update User Manager</DialogTitle>
        <DialogContent>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    type='text'
                    //@ts-ignore
                    value={selectedChipData?.name}
                    onChange={(e) => handleInputEditChange('name', e.target.value)}
                    required
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className='mt-2'>
                <Form.Label>Email</Form.Label>
                <InputGroup>
                <Form.Control
                    type='text'
                    //@ts-ignore
                    value={selectedChipData?.email}
                    onChange={(e) => handleInputEditChange('email', e.target.value)}
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className='mt-2 float-end btn-secondary'>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleUpdateManager()
              handleClose()
            }}
            variant='contained'
            className='mt-2 float-end btn-primary'
            //@ts-ignore
            disabled={selectedChipData?.email.length === 0 || selectedChipData?.name.length === 0}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MissionsForm
